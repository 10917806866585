<template>
  <div class="main wh-space-bottom">

    <div class="bar card">
      <div class="d-flex align-items-center">
        <b-form-group class="m-0 mr-1">
          <b-form-radio-group
            id="btn-radios-1"
            v-model="sizeSelected"
            button-variant="dark"
            :options="sizeOptions"
            buttons
            name="radios-btn-default"
            @change="onSizeChange"
          />
        </b-form-group>
      </div>
      <b-button variant="primary" @click="print" :disabled="isGenerating">{{ $t('kiosk.print') }}</b-button>
    </div>

    <div v-if="qrItems.length > 0" class="page card" :class="sizeSelected">
      <div v-show="isGenerating" class="qrInfo">
        <b-progress
          v-model="qrItemsPreparedCount"
          :max="qrItems.length"
        ></b-progress>
        <div>{{ qrItemsPreparedCount }} / {{ qrItems.length }}</div>
      </div>
      <div v-for="(item, i) in qrItemsPrepared" class="qrItem">
        <b-img v-if="item.qrBase64" :src="item.qrBase64" :alt="item.qrCode"/>
        <b-img v-else :src="emptyQrCode" alt="empty" />
        <div class="memberName" :class="{en: !item.chineseName}">{{ item.chineseName || item.englishName }}</div>
        <div class="memberCode en">{{ item.memberCode }}</div>
      </div>
    </div>

    <div v-else class="page card">No Data</div>
  </div>
</template>

<script>
import QrCode from 'qrcode'
import emptyQrCode from './blankQrCode.png'
import _ from 'lodash'

export default {
  name: "Preview.vue",
  data() {
    return {
      qrItems: JSON.parse(localStorage.getItem('kiosk_qrcode_preview_items')) ?? [],
      qrItemsPrepared: [],
      qrItemsPreparedCount: 0,
      isGenerating: false,
      sizeSelected: localStorage.getItem('kiosk_qrcode_preview_size') ?? 'S',
      sizeOptions: [
        {text: 'XS', value: 'XS', rowElmCount: 16},
        {text: 'S', value: 'S', rowElmCount: 8},
        {text: 'M', value: 'M', rowElmCount: 4},
        {text: 'L', value: 'L', rowElmCount: 2},
        {text: 'XL', value: 'XL', rowElmCount: 1},
      ],
      qrGeneratorOptions: {
        margin: 0,
        width: 400,
        type: 'image/png',
        errorCorrectionLevel: 'M',
        color: {
          dark: '#000000ff',
          light: '#ffffffff',
        }
      },
      emptyQrCode
    }
  },

  methods: {
    async genCodes() {
      if (this.isGenerating) return

      this.isGenerating = true
      this.qrItemsPrepared = []
      this.qrItemsPreparedCount = 0
      const qrItemsTmp = _.clone(this.qrItems)

      // use QrCode.toDataURL to generate base64, and update the qrItemsPreparedCount to show progress
      for (let [i, item] of qrItemsTmp.entries()) {
        await new Promise(resolve => {
          setTimeout(() => {
            if (item.qrCode) {
              QrCode.toDataURL(item.qrCode, this.qrGeneratorOptions).then((url) => {
                item.qrBase64 = url
                this.qrItemsPreparedCount++
                resolve()
              })
            } else {
                item.qrBase64 = ''
                this.qrItemsPreparedCount++
              resolve()
            }
          }, 50)
        })
      }

      this.qrItemsPrepared = qrItemsTmp
      this.isGenerating = false
    },

    onSizeChange(size) {
      localStorage.setItem('kiosk_qrcode_preview_size', size)
    },

    print() {
      if (this.qrItemsPrepared.length === 0) return
      window.print()
    }
  },

  mounted() {
    this.genCodes()
  }
}
</script>

<style lang="scss">
.card {
  padding: 1.5rem;
  flex-direction: initial;
  transition: none;
}
.main {
  margin: 20px auto;
  width: 80%;
  min-width: 800px;
  max-width: 9600px;
  .bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .page {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: var(--gap);
    .qrInfo {
      flex: 1 0 100%;
      text-align: center;
    }
    .qrItem {
      box-sizing: border-box;
      flex: 0 0 auto;
      margin: 0;
      padding: 0;
      font-size: var(--font-size);
      width: calc((100% - (var(--num-each-row) - 1) * var(--gap)) / var(--num-each-row));
      > img {
        width: 100%;
        display: block;
        font-size: 0.625em;
        margin-bottom: 5px;
      }
      > div {
        text-align: center;
        color: #000;
      }
      > .memberCode {
        font-size: var(--font-size-s);
        color: #666;
      }
      > .en {
        letter-spacing: -1.2px;
      }
    }
    &.XS {
      --num-each-row: 16;
      --gap: 1.5rem;
      --font-size: calc(0.5em + 0.5vw);
      --font-size-s: calc(0.4em + 0.25vw);
    }
    &.S {
      --num-each-row: 8;
      --gap: 3rem;
      --font-size: calc(0.5em + 1vw);
      --font-size-s: calc(0.4em + 0.5vw);
    }
    &.M {
      --num-each-row: 4;
      --gap: 6rem;
      --font-size: calc(0.5em + 2vw);
      --font-size-s: calc(0.4em + 1vw);
    }
    &.L {
      --num-each-row: 2;
      --gap: 8rem;
      --font-size: calc(0.5em + 4vw);
      --font-size-s: calc(0.4em + 2vw);
    }
    &.XL {
      --num-each-row: 1;
      --gap: 8rem;
      --font-size: calc(0.5em + 8vw);
      --font-size-s: calc(0.4em + 4vw);
    }
    .emptyItem {
      height: 0 !important;
    }
  }

}
@media print {
  @page {
    margin: 1.5cm;
  }
  #app {
    height: auto !important;
    .main {
      padding: 0;
      margin: 0;
      width: 100%;
      box-shadow: none;
      background-color: #fff;
      .bar {
        display: none;
      }
      .page {
        .qrItem {
          page-break-inside: avoid;
        }
      }
    }
  }
}
</style>
